import {
  Box,
  FormField,
  Cell,
  Page,
  Button,
  Layout,
  WixDesignSystemProvider,
  BrowserPreviewWidget,
  ColorInput,
  MarketingPageLayout,
  MarketingPageLayoutContent,
  Loader,
  Tooltip,
  Notification
} from "@wix/design-system";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import * as Icons from "@wix/wix-ui-icons-common";
import "@wix/design-system/styles.global.css";

const ScrollBar = () => {
  const [isClickedUpgrade, setIsClickedUpgrade] = React.useState(false);
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [isOpening, setIsOpening] = React.useState(false);
  const [isModified, setIsModified] = React.useState(false);
  const [highlightTextColor, setHighlightTextColor] = useState("#0F62E6");
  const CustomHighlightTextColor = styled.div`
    ::selection {
        background-color: ${highlightTextColor};
      }
      ::-moz-selection {
          background-color: ${highlightTextColor};
        }
        ::-webkit-selection {
            background-color: ${highlightTextColor};
          }
        `;
  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  });

  useEffect(() => {
    setIntercomIdentity();
    setInstance();
    getSettings();
  }, []);

  useEffect(() => {
    setIsModified(true);
  }, [
    highlightTextColor
  ]);

  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");

  // if (!instance) {
  //   return null
  // }

  const BASE_URL = `https://certifiedcode.wixsite.com/highlight/_functions`;

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash,
          });
        }
      })
      .catch(() => { });
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setInstanceData(data);
        setIsUpgraded(data.instance.isFree === false);
      })
      .catch(() => { });
  }

  function setSettings() {
    if (isOpening) {
      return;
    }
    setIsOpening(true);

    fetch(BASE_URL + "/settings", {
      method: "POST",
      headers: {
        Authorization: instance || "",
      },
      body: JSON.stringify({
        css: `
     
        ::selection {
        background-color: ${highlightTextColor};
      }
        ::-moz-selection {
        background-color: ${highlightTextColor};
     }
       ::-webkit-selection {
        background-color: ${highlightTextColor};
     }
    `,
        props: {
          highlightTextColor
        },
      }),
    }).then(() => {
      setIsOpening(false);
      setOpen(!open);
      setIsModified(false);
    });
  }

  function getSettings() {
    setIsOpening(true);
    fetch(BASE_URL + "/settings", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setIsOpening(false);
        if (data?.props) {
          setHighlightTextColor(data.props.highlightTextColor);
        }
      });
  }

  const handleHighlightColorChange = (event: any) => {
    setHighlightTextColor(event);
  };

  const applyCustomScrollbarStyles = (styles: any) => {
    // Create a new style element
    const styleElement = document.createElement("style");
    styleElement.innerHTML = styles;

    document.head.appendChild(styleElement);
  };

  const storedStyles = localStorage.getItem("customScrollbarStyles");
  if (storedStyles) {
    applyCustomScrollbarStyles(storedStyles);
  }

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=5f82bb33-d6a8-4ece-b4b5-8227df032be1&redirectUrl=https://certifiedcode.wixsite.com/highlight/_functions/@certifiedcode/base-backend/auth`;
    return <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page height="100vh"><Page.Content><Loader size="large"></Loader></Page.Content></Page>
    </WixDesignSystemProvider>
  }

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Notification show={isClickedUpgrade}>
        <Notification.TextLabel>
          Upgraded to Premium? Refresh the page to publish your changes.
        </Notification.TextLabel>
        <Notification.ActionButton onClick={() => {
          setInstance();
          setIsClickedUpgrade(false);
        }}>
          Refresh
        </Notification.ActionButton>
        <Notification.CloseButton />
      </Notification>
      <Page>
        <Page.Header
          title="Text Highlight: Color Editor"
          subtitle="You can change the text highlight style of your site here."
          actionsBar={
            <Box direction="horizontal" gap="SP3">
              {/* <Button skin="inverted" prefixIcon={<Icons.Preview />}>Preview</Button> */}
              <Tooltip content="Save your changes to apply them to your site.">
                <Button
                  disabled={!isUpgraded}
                  onClick={setSettings}
                  prefixIcon={isOpening ? undefined : <Icons.Confirm />}
                >
                  {isOpening ? (
                    <Loader size="tiny" />
                  ) : isModified ? (
                    "Save"
                  ) : (
                    "Saved"
                  )}
                </Button>
              </Tooltip>
              <Button
                as="a"
                target="_blank"
                onClick={() => {
                  setIsClickedUpgrade(true);
                }}
                href={`https://www.wix.com/apps/upgrade/${"5f82bb33-d6a8-4ece-b4b5-8227df032be1"}?appInstanceId=${instanceData?.instance?.instanceId
                  }`}
                prefixIcon={
                  isUpgraded ? <Icons.PremiumFilled /> : <Icons.Premium />
                }
                skin={isUpgraded ? "premium-light" : "premium"}
              >
                {isUpgraded ? "Manage Subscription" : "Upgrade to Set Live"}
              </Button>
            </Box>
          }
        />
        <Page.Content>
          <Layout>
            <Cell span={5}>
              <Box direction="vertical" gap="SP1">
                <FormField label="Highlight Color">
                  <ColorInput
                    value={highlightTextColor}
                    onChange={handleHighlightColorChange}
                  />
                </FormField>
              </Box>
            </Cell>
            <Cell span={7}>
              <BrowserPreviewWidget skin="gradient">
                <Box backgroundColor="#FFFFFF">
                  <MarketingPageLayout
                    horizontalSize="medium"
                    verticalSize="medium"
                    content={
                      <CustomHighlightTextColor
                        theme={CustomHighlightTextColor}
                      >
                        <MarketingPageLayoutContent
                          size="medium"
                          title="What is Lorem Ipsum?"
                          content={`Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.`}
                          actions={<Button size="medium">Sample Button</Button>}
                        />
                      </CustomHighlightTextColor>
                    }
                  />
                </Box>
              </BrowserPreviewWidget>
            </Cell>
            {/* <Cell>
              <Input type="button" onInputClicked={applyStyleToSite} value="Apply" />
            </Cell> */}
          </Layout>
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
};

export default ScrollBar;
